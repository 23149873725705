.fordrivers{
    display: flex;
   

    margin-left: 20px;
    padding-left: 20px;
    /* text-align: center; */
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
    
    li{
      margin-left: 20px;
    }

}

.fordrivers li{
  font-weight: 700;
}

@media(max-width:786px){
  .fordrivers ul{
    /* width: 100px; */
    margin: 0 auto;
  }

  
}




.fordrivers-img img{
  max-width: 40%; /* Adjust the size as necessary */
  height: auto; /* Maintain aspect ratio */
  position: absolute; /* Place the image inside the container */
  right: 0; /* Align it to the right */
  display: block; /* Ensure the image displays */
}

.fordrivers-img{
  display: flex;
  justify-content: center;
  align-items: center;




  position: relative; /* Ensures that child elements are positioned relative to this container */
  width: 100%; /* Ensures the container takes full available width */


}


@media (max-width: 768px){
  .fordrivers-img{
    display: none;
    margin-right: 20px;
  }
  .fordrivers {
    position: relative;  /* Position the element relative to contain the pseudo-element */
     margin-left: 0px;
     font-weight: 700;
     /* font-size: 20px; */
     text-align: center;

     

     li {
      margin-bottom: 0px;
      margin-left: 0px;
  }
  
  }
  
  .fordrivers::before {
    content: '';
    background-image: url(../../Assets/halfcar.png); /* Add the background image */
    background-size: cover;  /* Make the background cover the entire element */
    background-position: center; /* Center the background image */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;  /* Adjust the opacity here */
    z-index: -1;  /* Ensure the background stays behind other content */
  }
  
  
}


/* yellow border start */
  .main-border {
    position: relative;
    overflow: hidden;
    padding: 8px 0;
    box-sizing: border-box;
    background: #009d51; /* Change background of the div to white */
  
    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        90deg,
        rgb(255, 198, 26) 30%,         /* Yellow gradient */
        transparent 30%,
        transparent 75%,
        rgb(255, 198, 26) 75%          /* Yellow gradient */
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background-size: 20px;
      position: absolute;
      top: -1000px;
      left: -50px;
      bottom: -1000px;
      right: -50px;
      transform: rotate(45deg);
    }
  
    .content-main {
      background: #e4ffe3;   /* Change background of the content to white */
      height: 2px;
      width:100%;
    }
  }
  
/* end yellow border */


.fordrivers h3{
  color: rgb(255, 198, 26);
  
}
.fordrivers p {
  color: #009d51;

}
.fordrivers li {
  color: #009d51;

}