.services-about{
    max-width: 800px; /* Restrict width for readability on large screens */
  margin: 0 auto; /* Center the content */
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;


  h1{
    font-weight: 800;
  }


color: #009d51;

}


#services-h1{
  color: black;
}