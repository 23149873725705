#expenses-main-wrapper{
    display: flex;
}
#expenses-inner-wrapper{
    padding: 20px;
    background-color: #f5f5f5;
    max-width: 1280px;
    min-width: 100px;
    width: 100%;
}
.expenses-add-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid; */
    &>h4{
        margin-bottom: 0;
    }
    &>button{
        margin: 5px 25px;
        padding: 3px 32px;
        background-color: #212143;
        border-radius: 5px;
        color: #fff;
    }
}
/* Expenses Table CSS */

.expenses-table-container{
    background-color: #fff;
    padding: 8px 32px;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    &>h6{
        font-size: 20px;
        margin-top: 10px;
    }
}
.fee-manag-expenses-table{
    width: 100%;
    border: 1px solid;
    background-color: #fff;
    margin: 20px auto;

}

.fee-manag-expenses-table th{
    background-color: #f5f5f5;
    padding: 7px;
    font-weight: bold;
}

.fee-manag-expenses-table td,th{
    border: 1px solid #c9c9c9;
}

/* Add Expenses CSS */

.background-blurColor{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(189, 189, 189, 0.5);
    width: 100vw;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}
#expenses-popup-form{
    background-color:yellow;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(185, 185, 185);
    height: 350px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    &>h6{
        margin-bottom: 2rem;
    }
}

.background-custom{
    background-color: yellowgreen;
}

.expenses-cross-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f2f2f2;
    width: 32px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
    border-radius: 7px;
}
.expenses-form-tag{
    display: grid;
    grid-template-rows: repeat(3, 40px);
    grid-template-columns: repeat(1, minmax(100px, 100%));
    gap: 10px;

     &>.expense-div{
        /* border: 1px solid; */
        margin: 20px auto;
    }

    &>div>button{
        background-color: #212143;
        color: white;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        width: auto;
        margin:auto;
    }
    &>.expense-form-filed{
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;
        flex-wrap: nowrap;
        margin: 5px auto;
        width: 100%;
        padding: 10px;
        &>label{
            font-weight: 600;
            font-size: 18px;
            text-align: center;
        }
        &>input{
            border: 1px solid #c2c2c2;
            border-radius: 5px;
            outline: none;
            padding: 5px;
            /* width: 80%; */
            font-size: 16px;
            width: 290px;
          }
    }
}