.welcome-custom{
    background-color: transparent;
}
.welcome-custom h1{
    background-color: transparent;
}



/* text animation */

@keyframes scaleUp {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .custom-color {
    animation: scaleUp 1s ease-in-out;
    background-color: transparent;
  }
  
  .custom-color1 {
    animation: scaleUp 1s ease-in-out;
    background-color: transparent;
    /* animation-delay: 0.5s;  */
  }
  