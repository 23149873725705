.gallery-card-hover {
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.gallery-card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgb(255, 198, 26);
}

.gallery-centered-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 10px;
  transition: transform 0.3s ease;
  /* border-radius: 10px; */

}

.gallery-card-hover:hover .gallery-centered-image {
  transform: scale(1.1);
}

.gallery-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .gallery-carousel-image {
    height: auto;
    max-height: 400px;
  }
}

/* .gallery-slider {
} */

.gallery-carousel-caption {
  background-color: transparent;
}

.gallery-carousel-indicators {
  background-color: transparent;
}

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-card-img-top {
  height: 140px;
  /* object-fit: cover; */
}

@media (max-width: 768px) {
  .gallery-card-img-top {
    height: 150px;
  }
}

@media (max-width: 576px) {
  .gallery-card-img-top {
    height: 120px;
  }
}



/* .gallery-carousel-control-prev-icon,
.gallery-carousel-control-next-icon {
  background-color: rgb(
    255,
    207,
    79
  ); 
  border-radius: 50%; 
  width: 50px;
  height: 50px;
  position: absolute;
  top: 40px;
  bottom: 0;
} */