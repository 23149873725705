.privacy-policy-container {
    max-width: 800px; /* Restrict width for readability on large screens */
    margin: 0 auto; /* Center the content */
    padding: 60px;
    font-family: Arial, sans-serif;
    line-height: 1.6;

    margin-top: 20px;
    
  }
  
 

  #privacy-h1-h2{
    color: black;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container p {
    font-size: 16px;
    color: #009d51;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    /* padding-left: 20px; */
    margin-bottom: 20px;
    color: #009d51;

   
  }


 
  
  .privacy-policy-container li {
    margin-bottom: 10px;
    /* color: #009d51; */
    margin-left: 28px;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px; /* Reduce padding on smaller screens */
      font-size: 14px; /* Adjust text size for smaller screens */
    }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p, li {
      font-size: 1em; /* Ensure readability on smaller devices */
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy-container {
      padding: 10px; /* Reduce padding further on very small screens */
    }
  
    h1 {
      font-size: 22px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    p, li {
      font-size: 1em; /* Further reduce text size for smaller screens */
    }
  }
  