
  .timeline {
    display: block !important;
    margin-left: 0;
    padding-left: 0;
    /* background-color:  #e4ffe3; */
  }
  
  .timeline-card {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #436285 !important;
    position: relative;
    /* background-color:  #e4ffe3; */

  }
  
  .timeline li:before {
    content: "";
    width: 0.2rem;
    height: 100%;
    background-color: rgb(169 169 169);
    position: absolute;
    left: 16px;
    z-index: -1;
  }
  
  .timeline li:first-child:before {
    top: 50%;
    height: 50%;
  }
  
  .timeline li:last-child:before {
    bottom: 50%;
    height: 50%;
  }
  .timeline-title {
    margin-bottom: 0.5rem;
  }
  .circle-custom {
    font-size: 1rem !important;
  }
  
  .subcard {
    padding: 1em 1.5em 1.5em 1.5em;
    background-color:  #e4ffe3;
    color: black;
    width: -webkit-fill-available;
    min-height: 3.3rem;
  }
  

  .qr-card {
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    background-color:  #e4ffe3;

    margin-bottom: 10px;
  }
  
  .qr-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(251, 211, 12, 0.947);
  }
  
  .qr-card img {
   
    
      max-width: 100%; /* Ensure image scales down properly */
      height: auto;    /* Maintain aspect ratio */
    
  }


  img{
    max-width: 100%; /* Ensure image scales down properly */
    height: auto;    /* Maintain aspect ratio */
  
  }
  

.button-centre{
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin-top: 15px; */
}

.process-container h6{
  color: #009d50;

}

.process-container{
  color: #009d50;
}




@media (max-width: 768px) {
 
  .process-container{
    display: flex;
    flex-direction: column;
    /* margin-left: 40px; */
    /* margin-right: 40px; */

    padding-left: 20px;
  }

}





/* button */

.button-process{

  color: aliceblue;

  background-color: rgb(159, 52, 10);
  font-size: 16px;
  padding: 15px;
  width: auto;
  border-radius: 25px;
  font-weight: 800;
  border: none;

  cursor: pointer;
  transition: all 0.3s ease;
}

.button-process:hover {

  background-color: black;
  color: rgb(255, 198, 26);
  transform: scale(1.05); /* Scale the button slightly */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow */

}

#h1-how-does-work{
  color: black;
}
